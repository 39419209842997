<template>
    <v-container>
        <v-row>
            <v-col cols="12" md="4">
                <div class="text-center">
                    <v-btn 
                        @click="getDispensers"
                        small
                        fab
                        class="mb-3 botones"
                        elevation="5"
                        dark
                    >
                        <v-icon>mdi-update</v-icon> 
                    </v-btn>
                </div>
                <v-skeleton-loader v-if="loading_card" transition="fade-transition" :loading="loading_card" type="card"></v-skeleton-loader>
                <v-card
                    elevation="5"
                    dark
                    v-show="firstLoad"
                    class="mt-1 mb-4 card_p"
                    v-for="dispenser in dispensers" :key="dispenser.id_dispenser"
                >
                    <v-list-item three-line>
                        <v-list-item-content>
                            <div class="text-overline mb-4">
                                {{dispenser.name}}
                            </div>
                            <v-list-item-title class="text-h7 mb-2">
                                <span v-if="dispenser.network == 'Desconectado'"><v-icon color="red">mdi-checkbox-blank-circle</v-icon> {{dispenser.network}}</span>
                                <span v-else-if="dispenser.network == 'Conectado'"><v-icon color="green">mdi-checkbox-blank-circle</v-icon> {{dispenser.network}}</span>
                            </v-list-item-title>
                            <!--
                            <v-list-item-subtitle>
                                <span v-if="dispenser.status == 0">Sin alimento <v-icon color="red">mdi-food-drumstick-off</v-icon></span>
                                <span v-else-if="dispenser.status == 1">Con alimento <v-icon color="green">mdi-food-drumstick</v-icon></span>
                            </v-list-item-subtitle>-->
                        </v-list-item-content>
                            <span v-if="dispenser.status == 0">
                                <v-list-item-avatar
                                    tile
                                    size="80"
                                >
                                    <v-img src="../assets/dog-bowl.png"></v-img>
                                </v-list-item-avatar>
                            </span>
                            <span v-else-if="dispenser.status == 1">
                                <v-list-item-avatar
                                    tile
                                    size="80"
                                >
                                    <v-img src="../assets/pet-bowl.png"></v-img>
                                </v-list-item-avatar>
                            </span>
                    </v-list-item>
                    <span v-if="dispenser.network == 'Desconectado'">
                        <v-btn
                            text
                            block
                            @click="dialog = true"
                            class="mr-2"
                            color="error"
                        >
                            Alimentar
                        </v-btn>
                    </span>
                    <span v-else-if="dispenser.network == 'Conectado'">
                        <router-link :to="'/dispenser/'+dispenser.token" class="link">
                            <v-btn
                                text
                                block
                                class="mr-2"
                                color="success"
                            >
                                Alimentar
                            </v-btn>
                        </router-link>
                    </span>
                </v-card>
            </v-col>
        </v-row>
        <v-dialog
            v-model="dialog"
            width="500"
        >
            <v-card>
                <v-card-title class="text-h5 red lighten-2">
                    <v-icon class="mr-2">mdi-alert-circle</v-icon> Alerta
                </v-card-title>
                <v-card-text class="mt-5">
                    El dispensensador está sin conexión a internet.
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        text
                        @click="dialog = false"
                    >
                        Ok
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<script>   
    import axios from 'axios';
    //import md5 from "blueimp-md5"
    export default {
        name: 'Dispenser',
        data: () => ({
            dispensers: [],
            dispensers_new: [],
            loading_card: true,
            dialog: false,
            firstLoad: false,
            dispenser: {
                id_dispenser: null,
                name: '',
                token: '',
                status: '',
                feed:'',
                network:''
            }
        }),
        created() {
            this.getDispensers()
            const readyHandler = () => {
                if (document.readyState == 'complete') {
                    this.loading_card = false;
                    this.firstLoad = true;
                    document.removeEventListener('readystatechange', readyHandler);
                }
            };

            document.addEventListener('readystatechange', readyHandler);

            readyHandler();
            setInterval(() => {
                this.update()
            }, 1000)
        },
        methods:{
            getDispensers(){
                this.dispensers.splice(0, this.dispensers.length)
                let data = {
                    'opcion': '3',
                    'username': localStorage.getItem('username'),
                    'jwt_token': localStorage.getItem('token')
                }
                axios.post(this.$pathAPI, data)
                    .then((response) => {
                        if(response.status == 200){
                            let datos = response.data
                            if(datos.length){
                                this.dispensers = this.dispensers.concat(datos)
                                this.dispensers.forEach(token => {
                                    this.getNetStatus(token['token'])
                                }); 
                            }
                        }
                    })
                    .catch( (e) => {
                        console.log(e)
                        this.flashMessage.error({message: 'Error en la red!'});
                    })
            },
            update(){
                this.dispensers.forEach(token => {
                    this.getNetStatus(token['token'])
                    this.getStatus(token['token'])
                }); 
            },
            getNetStatus(_token){
                axios.post('https://serverpets.ine4celectronics.com/checkNet.php', {'opcion': '1', 'token': _token})
                    .then((response) => {
                        if(response.status == 200){
                            let datos = response.data
                            this.dispensers.forEach(dispenser => {
                                if(dispenser['token'] == _token){
                                    dispenser['network'] = datos["mensaje"]
                                }
                            });
                        }
                    })
                    .catch( (e) => {
                        console.log(e)
                        this.flashMessage.error({message: 'Error en la red!'});
                    })
            },
            getStatus(_token){
                axios.post('https://serverpets.ine4celectronics.com/checkNet.php', {'opcion': '3', 'token': _token})
                    .then((response) => {
                        if(response.status == 200){
                            let datos = response.data
                            this.dispensers.forEach(dispenser => {
                                if(dispenser['token'] == _token){
                                    dispenser['status'] = datos["mensaje"]
                                }
                            });
                        }
                    })
                    .catch( (e) => {
                        console.log(e)
                        this.flashMessage.error({message: 'Error en la red!'});
                    })
            }
        },  
    }
</script>
<style scope>
    .link{
        text-decoration: none;
        color: white;
    }
    .link:hover{
        color: #B7B7B7;
    }
    .card_p{
        background: #141E30;  /* fallback for old browsers */
        background: -webkit-linear-gradient(to bottom, #243B55, #141E30);  /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(to bottom, #243B55, #141E30); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    }
    .botones{
        background: #000000;  /* fallback for old browsers */
        background: -webkit-linear-gradient(to bottom, #434343, #000000);  /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(to bottom, #434343, #000000); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    }
</style>